video{
    object-fit:cover;
    width: 100%;
    height:100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /* background: url('images/img-home.jpg') center center/cover
    no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0 , 0.2);
    object-fit: contain;

}

.hero-container > h1 {
    color: white;
    font-size: 75px;
    margin-top: 150px;
}
.hero-container > h2 {
    margin-top: 8px;
    color: white;
    font-size: 32px;
    font-family: 'Trebuchet MS'
}
.hero-container > h3 {
    margin-top: 8px;
    color: white;
    font-size: 25px;
    font-family: 'Trebuchet MS'
}   

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1, h2, h3{
        font-size: 70px;
        margin-top: 150px;
        text-align: center;
    }
}

@media screen and (max-width: 375px) {
    .hero-container > h1, h2, h3 {
        font-size: 50px;
        margin-top: 100px;
    }
}

.button {
    display: inline;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .button:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }