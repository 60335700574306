.about {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 4rem;
    background: #fff;
}

.img__container{
    position: relative;
    padding: 30px;
   
}
.content__container{
    position: relative;
    padding: 0px 30px;
    
}
.content__container > p{
    font-size: 19px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    padding-right: 2rem;
    
    
    
}


.img__container > img {
    height: 500px;
   
    border-radius: 20px;

    
}
.about__container {
    display: grid;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;

    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    
    
}

.about__wrapper > p {
    margin-top: 25px;
    font-size: 25px;
    display: inline;
}

.about__wrapper {
    position: relative;
    margin: 10px 0 20px;
  }

@media screen and (max-width: 960px) {
    .img__container > img {
        height: 250px;
        
    }

    .img__container{
        position: relative;
        
       
    }
    .about__container {
        display: flex;
        flex-flow: column;
        align-items: center;
        max-width: 2000px;
        width: 90%;
       
        padding-top:2rem;
        padding-bottom: 4rem;
        
        
    }

    .content__container{
        position: relative;
        
    }
    .content__container > p{
        font-size: 19px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        justify-content: center;
        vertical-align: middle;
        text-align: center;
        padding-right: 0rem;
        
        
        
    }

}

@media screen and (max-width: 375px) {
    .img__container > img {
        height: 250px;
        
    }
    .about__container {
        display: flex;
        flex-flow: column;
        align-items: center;
        max-width: 1120px;
        width: 90%;
        margin: 0 auto;
        
        padding-top:2rem;
        padding-bottom: 4rem;

        
        
    }

    .content__container{
        position: relative;
       
    }
}

